<template>
  <div>
    <div v-if="!isTablet" class="test">
      <logo-component ref='logoComponent'></logo-component>
      <central-background ref='centralBackground' style="z-index: 0"></central-background>
      <div ref='iconsContainer' class="icons-container">
        <menu-item class="menu-item" id="gallery-icon" passed-id="gallery-icon" icon-name="gallery-icon.png"
          hoverTitle="Какво?"></menu-item>
        <menu-item class="menu-item" id="about-icon" passed-id="about-icon" hoverTitle="Как?"
          icon-name="about-icon.png"></menu-item>
        <menu-item class="menu-item" id="people-icon" passed-id="people-icon" icon-name="people-icon.png"
          hoverTitle="Кой?"></menu-item>
        <menu-item class="menu-item" id="comparison-icon" passed-id="comparison-icon" icon-name="comparison-icon.png"
          hoverTitle="Защо?"></menu-item>
        <menu-item class="menu-item" id="contacts-icon" passed-id="contacts-icon" icon-name="contacts-icon.png"
          hoverTitle="Контакти"></menu-item>
        <menu-item class="menu-item" id="language-icon" passed-id="language-icon" icon-name="language-icon-bg.png"
          hoverTitle="Език"></menu-item>
        <menu-item class="menu-item" id="creation-icon" passed-id="creation-icon" icon-name="creation-icon.png"
          hoverTitle="Пример"></menu-item>
        <menu-item class="menu-item" id="products-icon" passed-id="products-icon" icon-name="products-icon.png"
          hoverTitle="Продукти и услуги"></menu-item>
        <div v-if="showDropdown" class="switcherOverlay" @click="changeDropdown()"></div>
        <div v-if="showDropdown" id="language-switcher" class="dropdown-content">
          <a href="/nl">
            <div class="flagWrapper"><img src="./../assets/nl-flag.webp" /></div>Нидерландски
          </a>
          <a href="/en">
            <div class="flagWrapper"><img src="./../assets/uk-flag.webp" /></div>Английски
          </a>
        </div>
      </div>
    </div>
    <div v-else>
      <logo-component></logo-component>
      <central-background style="z-index: 0"></central-background>
      <div ref='iconsContainer' class="icons-container">
        <div class="tablet-and-mobile-menu-icon" @click="toggleMobileMenu" v-if="!isMobileMenuDisplayed">
          <img src="./../assets/menu_icon_mobile.svg" alt="">
        </div>
      </div>
    </div>
    <div class="mobile-menu" v-if="isMobileMenuDisplayed" ref="mobileMenu">
      <div class="mobile-menu-inner-container">
        <span class="menu-item-mobile" v-for="(menuItem, index) in mobileMenuItems" :key="index"
          @click="routeMobileMenu(menuItem.title)">
          {{ menuItem.title }}
        </span>
        <span class="menu-item-mobile">
          <a href="/nl" class="flagWrapper"><img src="./../assets/nl-flag.webp" />Нидерландски</a>
        </span>
        <span class="menu-item-mobile">
          <a href="/en" class="flagWrapper"><img src="./../assets/uk-flag.webp" />Английски</a>
        </span>
      </div>
      <div class="close-button" @click="toggleMobileMenu">
        X
      </div>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground";
import MenuItem from "@/components/MenuItem";
import LogoComponent from "@/components/LogoComponent.vue";

import { gsap } from 'gsap';

export default {
  name: "main-view",
  data() {
    return {
      isTablet: false,
      orientation: 'portrait',
      isMobileMenuDisplayed: false,
      showDropdown: false,
      mobileMenuItems: [
        { title: "Какво?" },
        { title: "Как?" },
        { title: "Кой?" },
        { title: "Защо?" },
        { title: "Пример" },
        { title: "Продукти и услуги" },
        { title: "Контакти" }
      ]
    }
  },
  components: {
    'logo-component': LogoComponent,
    'central-background': CentralBackground,
    'menu-item': MenuItem
  },
  computed: {

  },
  methods: {
    changeDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    computeLayout() {
      if (window.innerWidth <= 1024) {
        this.isTablet = true
      }
      else if (window.innerHeight <= 630) {
        this.isTablet = true
      }
      // else if(window.innerWidth < 768 && window.innerHeight < window.innerWidth) {
      //   this.orientation = 'landscape'
      //   this.isTablet = true
      // }
      else {
        this.isTablet = false
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuDisplayed = !this.isMobileMenuDisplayed;

      if (this.isMobileMenuDisplayed) {
        setTimeout(() => {
          this.$nextTick(() => {
            const mobileMenu = this.$refs.mobileMenu;

            if (mobileMenu) {
              const mobileMenuItems = mobileMenu.querySelectorAll('.menu-item-mobile');

              gsap.to(mobileMenuItems, {
                opacity: 1,
                y: 20,
                duration: 0.5,
                stagger: 0.2,
                ease: 'power2.out',
                delay: 0.5 // Add a delay before the animation starts
              });
            }
          });
        }, 100);
      }
    },
    routeMobileMenu(page) {
      switch (page) {
        case 'Контакти':
          this.changeRoute('contacts')
          break;
        case 'Кой?':
          this.changeRoute('team')
          break;
        case 'Какво?':
          this.changeRoute('concept')
          break;
        case 'Защо?':
          this.changeRoute('compare')
          break;
        case 'Пример':
          this.changeRoute('project')
          break;
        case 'Как?':
          this.changeRoute('about');
          break;
        case 'Продукти и услуги':
          this.changeRoute('products-and-services')
          break;
      }
    },
    changeRoute(dest) {
      this.$router.push(dest)
    }
  },
  mounted() {
    if (window.innerWidth <= 1024) {
      return this.isTablet = true
    }
    const icons = this.$refs.iconsContainer.children;

    gsap.set(icons, { opacity: 0 });

    const timeline = gsap.timeline({});

    Array.from(icons).forEach((icon, index) => {
      timeline.to(icon, { opacity: 1, duration: .5, stagger: 0 }, index * .5);
    });

  },
  created() {

    window.addEventListener("resize", this.computeLayout);
    let portrait = window.matchMedia("(orientation: portrait)");
    portrait.addEventListener("change", function (e) {
      if (e.matches) {
        this.computeLayout()
      } else {
        this.computeLayout()
      }
    })
    this.computeLayout()
  },
  destroyed() {
    window.removeEventListener("resize", this.computeLayout);
    window.removeEventListener("change", this.computeLayout)
  },
}
</script>

<style scoped>
.switcherOverlay {
  z-index: 9998;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.switcherOverlay:hover {
  cursor: default !important;
}

.dropdown-content {
  font-family: "Beer Money", serif;
  font-size: 20px;
  top: 38%;
  left: 44%;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-radius: 5px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;

}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content a img {
  width: 30px;
  margin-right: 10px;
  height: 25px;
  object-fit: cover;
}

.menu-item-mobile img {
  width: 30px;
  margin-right: 10px;
  height: 25px;
  object-fit: cover;
}

.menu-item-mobile a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

.flagWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-component {
  position: relative;
  transform: translate(59%, 46%);
}

.mobile-menu {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(4, 96, 107, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-menu-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-bottom: 40px;
}

.menu-item-mobile {
  cursor: pointer;
  font-family: "Comic Sans MS", serif;
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.menu-item-mobile:hover {
  scale: 1.2;
}

.close-button {
  font-size: 48px;
  margin-bottom: 100px;
  color: #fff;
  cursor: pointer;
  font-family: "Comic Sans MS";
}

/* Styles for screens equal to or larger than 1920x1080 */
@media (min-width: 1536px) {
  #about-icon {
    top: 25%;
    left: 67%;
  }

  #people-icon {
    top: 55%;
    left: 68%;
  }

  #comparison-icon {
    top: 85%;
    left: 60%;
  }

  #contacts-icon {
    top: 92%;
    left: 43%;
  }

  #creation-icon {
    top: 48%;
    left: 28%;
  }

  #products-icon {
    top: 10%;
    left: 35%;
  }

  #language-icon {
    top: 80%;
    left: 32%;
  }

  #gallery-icon {
    top: 10%;
    left: 58%;
  }

  .dropdown-content {
    top: 62%;
    left: 29%;
  }
}


@media (min-width: 1600px) {
  .dropdown-content {
    top: 62%;
    left: 29%;
  }
}

@media (max-width: 1536px) {
  #creation-icon {
    top: 48%;
    left: 22%;
  }

  #about-icon {
    top: 25%;
    left: 75%;
  }

  #people-icon {
    top: 55%;
    left: 75%;
  }

  #comparison-icon {
    top: 87%;
    left: 62%;
  }

  #contacts-icon {
    top: 92%;
    left: 35%;
  }

  #products-icon {
    top: 10%;
    left: 35%;
  }

  #language-icon {
    top: 75%;
    left: 27%;
  }

  #gallery-icon {
    top: 10%;
    left: 60%;
  }

  .dropdown-content {
    top: 62%;
    left: 29%;
  }
}

@media (max-width: 1366px) {
  #creation-icon {
    top: 48%;
    left: 19%;
  }

  #about-icon {
    top: 25%;
    left: 75%;
  }

  #people-icon {
    top: 55%;
    left: 75%;
  }

  #comparison-icon {
    top: 87%;
    left: 62%;
  }

  #contacts-icon {
    top: 92%;
    left: 35%;
  }

  #products-icon {
    top: 10%;
    left: 35%;
  }

  #language-icon {
    top: 80%;
    left: 32%;
  }

  #gallery-icon {
    top: 10%;
    left: 60%;
  }
}

@media (max-width: 1280px) {
  #creation-icon {
    top: 48%;
    left: 13%;
  }

  #about-icon {
    top: 20%;
    left: 80%;
  }

  #people-icon {
    top: 55%;
    left: 80%;
  }

  #comparison-icon {
    top: 87%;
    left: 62%;
  }

  #contacts-icon {
    top: 92%;
    left: 30%;
  }

  #products-icon {
    top: 10%;
    left: 35%;
  }

  #language-icon {
    top: 80%;
    left: 32%;
  }

  #gallery-icon {
    top: 10%;
    left: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .mobile-menu-inner-container {
    margin-bottom: 25%;
  }

  .logo-component-tablet {
    text-align: center;
  }

  .icons-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 90px;
    display: flex;
    justify-content: center;
  }

  .tablet-and-mobile-menu-icon {
    cursor: pointer;
  }

  #about-icon,
  #people-icon,
  #comparison-icon,
  #contacts-icon,
  #creation-icon,
  #products-icon,
  #gallery-icon {
    position: relative;
  }

  #creation-icon {
    top: 45%;
    left: -16%;

  }

  #about-icon {
    top: 0%;
    left: 19%;
  }

  #people-icon {
    top: 0%;
    left: 25%;
  }

  #comparison-icon {
    top: 45%;
    left: 23%;
  }

  #contacts-icon {
    top: 0%;
    left: 21%;
  }

  #products-icon {
    top: 0%;
    left: 18%;
  }

  #language-icon {
    top: 80%;
    left: 32%;
  }

  #gallery-icon {
    top: 45%;
    left: -2%;
  }
}

/*GENERIC CSS STYLES BELOW*/
@media screen and (min-width: 1025px) and (min-height: 631px) {
  .icons-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    width: 100%;
  }

  .menu-item {
    font-family: "Comic Sans MS", serif;
  }

  .menu-item:hover {
    background: linear-gradient(0deg, rgba(0, 101, 110, 0.8827906162464986) 0%, rgba(0, 101, 110, 0.41220238095238093) 36%, rgba(0, 101, 110, 0.15449929971988796) 72%, rgba(0, 101, 110, 0) 100%);
  }

  .icons-container>div:hover {
    cursor: pointer;
  }

  #about-icon,
  #people-icon,
  #comparison-icon,
  #contacts-icon,
  #creation-icon,
  #products-icon,
  #gallery-icon {
    position: absolute;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1536px) and (max-height: 900px) {
  #products-icon {
    top: 11%;
    left: 26%;
  }

  .logo-component {
    transform: translate(59%, 0);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1536px) and (max-height: 900px) {
  .logo-component {
    transform: translate(59%, 0);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1536px) and (max-height: 665px) {
  .logo-component {
    transform: translate(40%, 0) scale(.6);
  }

  #products-icon,
  #about-icon,
  #comparison-icon,
  #contacts-icon,
  #creation-icon,
  #gallery-icon,
  #people-icon {
    transform: scale(.8);
  }

  #people-icon {
    left: 74%;
  }

  #about-icon {
    top: 20%;
    left: 74%;
  }

  #creation-icon {
    left: 20%;
  }
}

/*TABLET STYLES*/
@media (max-height: 630px) {

  /* CSS rules here */
  .logo-component-tablet {
    position: relative;
    margin-top: 15px;
  }

  .mobile-menu-inner-container {
    margin-bottom: 25%;
  }

  .logo-component-tablet {
    text-align: center;
  }

  .icons-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 90px;
    display: flex;
    justify-content: center;
  }

  .tablet-and-mobile-menu-icon {
    cursor: pointer;
  }
}

@media screen and (orientation: landscape) and (max-height: 400px) {
  .mobile-menu-inner-container {
    margin-bottom: 5px;
    margin-top: 50px;
    padding: 20px;
    transform: scale(.7);
  }

  .close-button {
    transform: translate(0, -60px) scale(.7);
  }
}

#language-icon {
  width: 75px;
  height: 75px;
}
</style>
