import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import Routes from "@/router/routes";

Vue.config.productionTip = false

import VueImg from 'v-img';
import VueMeta from 'vue-meta'

Vue.use(VueImg);
Vue.use(VueMeta);

Vue.use(VueRouter)
const router = new VueRouter({
  routes: Routes,
  mode: "history",
  base: '/bg',
  "navigationFallback": {
    "rewrite": "/index.html",
    "exclude": ["/images/*.{png,jpg,gif}", "/css/*"]
  }
})
new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

