<template>
  <div ref="centralBackground" id="background-image" class="centered">
    <img v-if="this.$route.path === '/project'" src='../assets/background_zoomed.png' alt="background-image">
    <img v-else src='./../assets/1536x864.png' alt="background-image">
  </div>
</template>

<script>
import {gsap} from "gsap";

export default {
  name: "CentralBackground",
  computed: {
    computeBackgroundImage() {
      return this.$route.path === '/project' ? './../assets/background_zoomed_mobile.png' : './../assets/1536x864.png'
    }
  },
  mounted() {
    gsap.from(this.$refs.centralBackground, { opacity: 0, duration: 5, stagger: 0.1 });
    const zoomScreen = document.querySelector("#background-image");
    let zoom = .7;
    const zoomingSpeed = 0.01;

    document.addEventListener("wheel", (e)=> {
      if(this.$route.path === '/'){
        if (e.deltaY > 0) {
            if(zoom <= 1.5) {
              zoomScreen.style.transform = `translate(-50%, -50%) scale(${(zoom += zoomingSpeed)})`;
            }
        } else {
            if(zoom > 0.4){
              zoomScreen.style.transform = `translate(-50%, -50%) scale(${(zoom -= zoomingSpeed)})`;
            }
        }
      }
    })

    document.addEventListener("touchmove", (e) => {
      e.preventDefault()
      zoomScreen.style.transform = `translate(-50%, -50%) scale(${(zoom = ((e.touches[0].clientY / window.innerHeight)))})`;
    })
  }
}
</script>

<style scoped>
  #background-image {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*opacity: .6;*/
  }

  @media screen and (min-width: 1024px) and (max-height: 900px) {
    #background-image {
      transform: translate(-50%, -50%) scale(.8);
    }
  }
  @media screen and (min-width: 1024px) and (max-height: 665px) {
    #background-image {
      transform: translate(-50%, -50%) scale(.7);
    }
  }
  @media screen and (max-width: 1024px) {
    #background-image {
      transform: translate(-50%, -50%) scale(.7);
    }
  }
  @media screen and (max-width: 1024px) and (max-height: 700px) {
    #background-image {
      transform: translate(-50%, -50%) scale(.6);
    }
  }
  @media screen and (max-width: 1024px) and (max-height: 486px) {
    #background-image {
      transform: translate(-50%, -50%) scale(.4);
    }
  }
  @media screen and (orientation: landscape) and (max-height: 420px)  {
    #background-image {
      transform: translate(-50%, -50%) scale(.3);
    }
  }

</style>
