<template>
  <div>
    <logo-component :on-page='true'></logo-component>
    <central-background style="z-index: 0" class="central-background"></central-background>
    <div class="content-template-wrapper">
      <div class="content-container">
        <div class="return-home-button" @click="$router.go(-1)">
          <div title="Back to Menu" class="back-arrow">
            &#10140;
          </div>
        </div>
        <div class="text-content-container paragraph">
          <div class="text-content first-phrase">
            <span class="first-phrase-span">Продукти и услуги</span>
          </div>
          <div class="text-content">
            <ul>
              <li>
                Изработка на енергийно ефективни мобилни домове от екологични материали
              </li>
              <li>
                Екстериорен, интериорен и конструкционен дизайн на мобилни домове
              </li>
              <li>
                Транспорт на мобилни домове между местата за отсядане
              </li>
            </ul>
            <div class="image-container">
              <img v-img class="image-element left" src="./../assets/products_and_services.png" alt="Изолация еко дом">
              <img v-img class="image-element right" src="./../assets/products_and_services_2.png" alt="Изолация еко дом">
              <img v-img class="image-element left" src="./../assets/products_and_services_3.png" style="margin-top: 2%" alt="Изолация еко дом">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground";
import LogoComponent from "@/components/LogoComponent.vue";

import {gsap} from "gsap";

export default {
  name: "ProductsAndServices",
  components: {
    'central-background': CentralBackground,
    'logo-component': LogoComponent,
  },
  mounted() {
    const paragraphs = document.querySelectorAll('.paragraph');
    gsap.from(paragraphs, { opacity: 0, y: -20, duration: 1, stagger: 0.5 })
  }
}
</script>

<style scoped>
.content-template-wrapper {
  position: absolute;
}
.content-container {
  position: relative;
  transform: translate(20%, 20%);
  width: 70vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
}
.text-content-container {
  padding: 2.5%;
}
.first-phrase-span {
  display: block;
  margin: 10px 0;
}
li {
  margin: 10px;
}
.text-content {
  font-family: 'Comic Sans MS',serif;
  font-size: 24px;
  color: #f2f2f2;
}
.return-home-button {
  position: relative;
  top: 10px;
  left: 10px;
}
.back-arrow {
  font-size: 25px;
  line-height: 25px;

  width: 1.5em;
  height: 1.5em;
  padding: 5px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);

  color: white;
  border: 2px solid white;
  border-radius: 50%;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.back-arrow:hover {
  background: rgba(255,255,255,.5);
}
.image-container {
  display: inline-block;
  padding-bottom: 2%;
  box-sizing: border-box
}

.image-container .left{
  margin-right: 2% !important;
}

.image-element {
  width: 47%;
}


@media screen and (max-width: 1366px) {
  .content-container {
    position: relative;
    transform: translate(5%, 15%);
    width: 90vw;
    height: 75vh;
    background: #04606B;
    opacity: .9;
    overflow: hidden;
    overflow-y: scroll;
  }
  .image-container {
    flex-direction: column;
  }
  .image-container .left{
    padding-right: 0% !important;
  }
  .image-element {
    width: 100%;
    margin: 10px;
  }
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }

  .content-container::-webkit-scrollbar-track {

  }

  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}

@media screen  and (min-width: 1366px) and (max-height: 900px) {
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }

  .content-container::-webkit-scrollbar-track {

  }

  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
</style>
