<template>
  <div>
    <logo-component :on-page='true' ></logo-component>
    <central-background style="z-index: 0" class="central-background"></central-background>
    <div class="content-template-wrapper">
      <div class="content-container">
        <div class="return-home-button" @click="$router.go(-1)">
          <div title="Back to Menu" class="back-arrow">
            &#10140;
          </div>
        </div>
        <div class="text-content-container">
          <div class="text-content">
            <div class="info-section" ref="sectionOne">
              <ul>
                <li class="name-border-style">
                  Стефан
                </li>
                <p>
                  Инженер по автоматизация на системи, с европейска специализация по
                  отопление и охлаждане с геотермални термопомпи, и разнороден инженерен
                  опит. Харесва приключенията, откровените взаимоотношения, прецизната
                  изработка, хармонията във всичко, да бърза бавно, да внимава много.
                </p>
              </ul>
            </div>
            <div class="info-section" ref="sectionTwo">
              <ul>
                <li class="name-border-style">
                  Стефанa
                </li>
                <p>
                  Мениджър по образование и дизайнер по душа, с хуманитарен, и
                  организационен опит в европейски проекти в страната и чужбина. Харесва
                  досега с природата, слънцето над морето, магията на планината, да мечтае, да
                  създава уют, да общува с хора.
                </p>
              </ul>
            </div>
            <div class="info-section" ref="sectionThree">
              <ul>
                <li class="name-border-style">
                  Стефанов
                </li>
                <p>
                  Строителен специалист с двайсет и пет годишен опит с иновативно
                  строителство в България и Германия, включително сглобяеми къщи. Харесва
                  да разрешава технически и социални предизвикателства, да създава нови
                  продукти и технологии, да гледа ведро на нещата, да спортува, да танцува.
                </p>
              </ul>
            </div>
            <div class="info-section" ref="sectionFour">
              <ul>
                <li class="name-border-style">
                  Екип
                </li>
                <p>
                  Проектирането и изпълнението на уютни мобилни еко домове обединяват
                  може би всички наши знания, умения и хобита – прекрасна мотивация да
                  творим за каузата на дружеството.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground.vue";
import LogoComponent from "@/components/LogoComponent.vue";
import {gsap} from "gsap";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Team",
  components: {
    'central-background': CentralBackground,
    'logo-component': LogoComponent,
  },
  mounted() {
    const tl = gsap.timeline({ delay: 0.5 }); // Create a timeline

    // Set initial positions
    tl.fromTo(this.$refs.sectionOne, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.sectionTwo, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.sectionThree, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.sectionFour, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });

  }
}
</script>

<style scoped>
.content-template-wrapper {
  position: absolute;
}
.content-container {
  position: relative;
  transform: translate(20%, 20%);
  width: 70vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
}
.text-content-container {
  padding: 2.5%;
}
.first-phrase-span {
  display: block;
  margin: 10px 0;
}
li {
  margin: 10px;
}
.name-border-style {
  border-bottom: 5px dotted #857C5E;
  width: 90px;
}
.shorter-name-border {
  width: 60px;
}
.text-content {
  font-family: 'Comic Sans MS',sans-serif;
  font-size: 24px;
  color: #f2f2f2;
}
.return-home-button {
  position: relative;
  top: 10px;
  left: 10px;
}
.back-arrow {
  font-size: 25px;
  line-height: 25px;

  width: 1.5em;
  height: 1.5em;
  padding: 5px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);

  color: white;
  border: 2px solid white;
  border-radius: 50%;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.back-arrow:hover {
  background: rgba(255,255,255,.5);
}
@media screen  and (max-width: 1366px) {
  .content-container {
    position: relative;
    transform: translate(5%, 15%);
    width: 90vw;
    height: 75vh;
    background: #04606B;
    opacity: .9;
    overflow: hidden;
    overflow-y: scroll;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }

  .content-container::-webkit-scrollbar-track {

  }

  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }

}

@media screen  and (min-width: 1366px) and (max-height: 900px) {
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }

  .content-container::-webkit-scrollbar-track {

  }

  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}

</style>