<template>
  <div>
    <logo-component :on-page='true' ></logo-component>
    <central-background style="z-index: 0" class="central-background"></central-background>
    <div class="content-template-wrapper">
      <div class="content-container">
        <div class="return-home-button" @click="$router.go(-1)">
          <div title="Back to Menu" class="back-arrow">
            &#10140;
          </div>
        </div>
        <div class="text-content-container">
          <div class="text-content first-phrase">
            <span class="first-phrase-span">Контакти</span>
          </div>
          <div class="text-content"  ref="sectionOne">
            <div class="business-card-mobile">
              <div class="name">
                Идеиници ООД
              </div>
              <div class="contacts-container">
                <span class="email">info@ideinitsi.bg</span>
                <span class="email">Варна и София</span>
              </div>
            </div>
            <business-card class="business-card" first-name="Идеиници ООД" last-name="" email="info@ideinitsi.bg" occupation="" phone="Варна и София"></business-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground";
import LogoComponent from "@/components/LogoComponent.vue";
import BusinessCard from "@/components/BusinessCard.vue";
import {gsap} from "gsap";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Contacts",
  components: {
    BusinessCard,
    'central-background': CentralBackground,
    'logo-component': LogoComponent,
  },
  mounted() {
    const tl = gsap.timeline({ delay: 0.5 }); // Create a timeline
    tl.fromTo(this.$refs.sectionOne, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });

  }
}
</script>

<style scoped>
.text-content {
  padding-left: 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.business-card {
  margin: 15px 45px;
}
.content-template-wrapper {
  position: absolute;
}
.content-container {
  position: relative;
  transform: translate(20%, 20%);
  width: 70vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
}
.text-content-container {
  padding: 0 2.5%;
}
.first-phrase-span {
  display: block;
  margin: 10px 0;
}
li {
  margin: 10px;
}
.text-content {
  font-family: 'Comic Sans MS',serif;
  font-size: 24px;
  color: #f2f2f2;
}
.return-home-button {
  position: relative;
  top: 10px;
  left: 10px;
}
.back-arrow {
  font-size: 25px;
  line-height: 25px;

  width: 1.5em;
  height: 1.5em;
  padding: 5px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);

  color: white;
  border: 2px solid white;
  border-radius: 50%;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.back-arrow:hover {
  background: rgba(255,255,255,.5);
}

@media screen  and (max-width: 1366px) {
  .content-container {
    position: relative;
    transform: translate(5%, 15%);
    width: 90vw;
    height: 75vh;
    background: #04606B;
    opacity: .9;
    overflow: hidden;
    overflow-y: scroll;
  }
}

@media (max-width: 968px) {
  .business-card {
    display: none;
  }
  .business-card-mobile {
    display: block;
  }
  .business-card-mobile {
    //border: 4px solid white;
    margin: 2px;
    padding: 5px;
    width: 100%;
    font-size: 14px;
  }
  .contacts-container {
    display: flex;
    flex-direction: column;
  }

  .content-container > * {
    padding: 5px 0;
    margin: 5px 0;
  }
}

@media (min-width: 968px) {
  .business-card {
    display: block;
  }
  .business-card-mobile {
    display: none;
  }
}
@media screen  and (min-width: 1366px) and (max-height: 900px) {
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }

  .content-container::-webkit-scrollbar-track {

  }

  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
</style>