import { render, staticRenderFns } from "./CentralBackground.vue?vue&type=template&id=068a5dfa&scoped=true"
import script from "./CentralBackground.vue?vue&type=script&lang=js"
export * from "./CentralBackground.vue?vue&type=script&lang=js"
import style0 from "./CentralBackground.vue?vue&type=style&index=0&id=068a5dfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068a5dfa",
  null
  
)

export default component.exports