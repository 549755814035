import Main from "@/view/Main.vue";
import About from "@/view/About.vue";
import ProductsAndServices from "@/view/ProductsAndServices.vue";
import Contacts from "@/view/Contacts.vue";
import Team from "@/view/Team.vue";
import Concept from "@/view/Concept.vue";
import Compare from "@/view/Compare.vue";
import Project from "@/view/Project.vue";


////////// PAGES FOR THE PROJECT

import ProjectInfoComponent from "@/view/pages/ProjectInfoComponent.vue";
// import PorchwayPage from "@/view/pages/PorchwayPage.vue";
export default [
    {
        path: '/',
        component: Main
    },
    {
        path:'/about',
        component: About
    },
    {
        path:'/products-and-services',
        component: ProductsAndServices
    },
    {
        path: '/contacts',
        component: Contacts
    },
    {
        path: '/team',
        component: Team
    },
    {
        path: '/concept',
        component: Concept
    },
    {
        path: '/compare',
        component: Compare
    },
    {
        path: '/project',
        component: Project
    },

    {
        path: '/project-info/:project-property', //removing dynamic tag.
        name: 'project-info',
        component: ProjectInfoComponent,
        meta: {

        }
    },

    {
        path: '*',
        component: Main
    },

    
]