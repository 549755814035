<template>
  <div class="container" @click="cardClicked">
    <div :class="['card', {'flipped': isClicked}]">
      <figure class="front">
        <div class="caption">
          <h2>{{firstName}} <span>{{lastName}}</span></h2>
          <p>{{occupation}}</p>
        </div>
      </figure>
      <figure class="back">
        <div class="caption">
          <div class="back-text">
            <div class="back-text-container">
              <div class="telephone">
                <span> </span>
                <span>{{phone}}</span>
              </div>
              <div class="email">
                <span>{{email}}</span>
              </div>
            </div>
          </div>
        </div>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessCard",
  data() {
    return {
      isClicked: false
    }
  },
  props: ['firstName', 'lastName', 'occupation', 'phone', 'email'],
  methods: {
    cardClicked() {
      return this.isClicked = !this.isClicked
    }
  }
}
</script>

<style scoped>
.container, figure{
  width:450px;
  height: 270px;
}

.container{
  perspective:1000;
}

.card{
  position: relative;
  transition: 0.6s;
  transform-style: preserve-3d;
}

figure{
  background: rgb(122,114,81, 0.7);
  color: #fff;
  backface-visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  cursor: pointer;
  transition: 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 1px 5px rgba(0,0,0,0.9);
}

figure.front{
  transform: rotateY(0deg);
  z-index: 2;
}

figure.back,
.card.flipped{
  transform: rotateY(180deg);
}

figure .caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  padding: 2em;
  backface-visibility: hidden;
}

.front .caption{
  font-size: 1.25em;
}

.front .caption:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(72,76,97,0) 0%, rgba(157,146,115,0.8) 75%);
  content: '';
  opacity: 0;
  transform: translate3d(0,50%,0);
  transition: opacity 0.35s, transform 0.35s;
}

.front:hover .caption:before{
  opacity: 1;
  transform: translate3d(0,0,0);
}

.front h2{
  word-spacing: -0.15em;
  font-weight: 300;
  font-size: 1.6em;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #272833;
  transition: transform 0.35s, color 0.35s;
  transform: translate3d(0,-50%,0);
}

.front h2 span {
  font-weight: bold;
}

.front h2:after{
  position: absolute;
  bottom: -10px;
  left: 70px;
  right: 70px;
  height: 2px;
  background: #fff;
  content: '';
  transition: transform 0.35s;
  transform: translate3d(-130%,0,0);
}

.front:hover h2 {
  color: #fff;
  -webkit-transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
  transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
}

.front:hover h2:after {
  transform: translate3d(0,0,0);
}

.front p {
  letter-spacing: 1px;
  font-size: 68.5%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  opacity: 0;
  transform: translate3d(0,10px,0);
  transition: opacity 0.35s, transform 0.35s;
}

.back .caption:before,
.back .caption:after{
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  transition: opacity 0.45s, transform 0.45s;
  transition-delay: 1s;
}

.back .caption:before{
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: scale(0,1);
}

.back .caption:after{
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: scale(1,0);
}

.card.flipped .back .caption:before,
.card.flipped .back .caption:after {
  opacity: .9;
  transform: scale(1);
}

.back dl{
  font-family:'Lato', Arial, sans-serif;
  font-size: 14px;
  font-weight:300;
  bottom:40px;
  left:40px;
  position: absolute;
  opacity: 0;
  transition: opacity .35s, transform .35s;
  transition-delay: .85s;
  transform: translate3d(-40px,0,0);
}

.card.flipped .back dl {
  opacity: 1;
  transform: translate3d(0,0,0);
}

.back-text {
  position: relative;
  top: 80%;
  font-size: 18px;
  text-align: left;
}

.back-text > span {
  display: inline-block;
  padding: 5px;
  width: 50%;
}

figure a{
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 968px) {
  .container, figure{
    width:380px;
    height: 270px;
  }
}
</style>
