<template>
  <div @mouseenter.stop="startAnimation" @mouseleave.stop="resetAnimation" @click='routeMap(hoverTitle)'
    ref='iconElement' :class="['menu-item', 'border-gradient', { 'is-hovered': isHovered }]">
    <div ref="notHovered"
      :class="{ 'inner-icon-container': !isHovered, 'text-container': isHovered, 'language-icon-container': passedId === 'language-icon' }">
      <img v-show="!isHovered" :class="['icon-image', { 'products-icon-image': passedId === 'products-icon' }]"
        :src="require('@/assets/' + iconName)">
      <span v-show="isHovered"
        :class="['title', 'title-rest', { 'title-about': passedId === 'about-icon' }, { 'products-icon': passedId === 'products-icon' }, { 'title-rest-language': passedId === 'language-icon' }]">{{ this.hoverTitle }}</span>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

// import Test from "@/components/Test.vue";

export default {
  name: "MenuItem",
  components: {
    // Test
  },
  data: () => {
    return {
      isHovered: false,
      animation: null
    }
  },
  props: ['iconName', 'hoverTitle', 'passedId'],
  methods: {
    changeRoute(dest) {
      this.$router.push(dest)
    },
    routeMap(page) {
      switch (page) {
        case 'Контакти':
          this.changeRoute('contacts')
          break;
        case 'Кой?':
          this.changeRoute('team')
          break;
        case 'Какво?':
          this.changeRoute('concept')
          break;
        case 'Защо?':
          this.changeRoute('compare')
          break;
        case 'Пример':
          this.changeRoute('project')
          break;
        case 'Как?':
          this.changeRoute('about');
          break;
        case 'Продукти и услуги':
          console.log(this.passedId, 'passed id')
          this.changeRoute('products-and-services')
          break;
        case 'Език':
          this.$parent.changeDropdown();
          break;
      }
    },
    startAnimation() {
      this.animation.pause()
      this.animation.play();
      this.isHovered = !this.isHovered
    },
    resetAnimation() {
      console.log('mouse leave')
      this.animation.pause()
      this.animation.reverse();
      this.isHovered = !this.isHovered
    },
  },
  mounted() {
    this.animation = gsap.timeline({ paused: true })
      // .to(this.$refs.iconElement, { scale: 1.3, duration: 0.3, ease: "power2.out" })
      .to(this.$refs.iconElement, { scale: 1.7, duration: .6, ease: "power2.in" });
  },
  beforeUnmount() {
    // Clean up by removing event listeners and killing the animation
    this.$refs.iconElement.removeEventListener("mouseenter", this.startAnimation);
    this.$refs.iconElement.removeEventListener("mouseleave", this.resetAnimation);
    this.animation.kill();
  },
}
</script>

<style scoped>
.test {
  font-family: "Comic Sans MS", serif;
}

.menu-item {
  position: relative;
  background: none;
  width: 90px;
  height: 90px;
}

.menu-item:hover {
  /*transform: scale(1.5);*/
  border-radius: 50%;
  /*background: linear-gradient(0deg, rgba(0,101,110,0.8827906162464986) 0%, rgba(0,101,110,0.41220238095238093) 36%, rgba(0,101,110,0.15449929971988796) 72%, rgba(0,101,110,0) 100%);*/
}

.border-gradient {
  position: relative;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  padding: 5px;
  background: radial-gradient(circle, rgba(0, 101, 110, 1) 0%, rgba(0, 101, 110, 1) 36%, rgba(0, 101, 110, 0.4962359943977591) 72%, rgba(0, 101, 110, 0.44021358543417366) 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/*.border-gradient::before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  inset: 0;*/
/*  border-radius: 50%;*/
/*  opacity: .5;*/
/*  padding: 5px;*/
/*  background:radial-gradient(circle, rgba(0,101,110,1) 0%, rgba(0,101,110,1) 36%, rgba(0,101,110,0.4962359943977591) 72%, rgba(0,101,110,0.44021358543417366) 100%);*/
/*  -webkit-mask:*/
/*      linear-gradient(#fff 0 0) content-box,*/
/*      linear-gradient(#fff 0 0);*/
/*  -webkit-mask-composite: xor;*/
/*  mask-composite: exclude;*/
/*}*/
.border-gradient:hover::before {
  content: "";
  /*background: ;*/

}

.inner-icon-container {
  height: 95px;
  width: 95px;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-image {
  height: 145px;
  position: relative;
  left: 48%;
  bottom: 25px;
  transform: translateX(-50%);
}

.products-icon-image {
  left: 50%;
}

/*ON HOVER*/

.text-container {
  position: relative;
  width: 80px;
  text-align: center;
  max-width: 80px;
  height: 80px;
  left: 8px;
  top: 30px;
}

.title {
  font-family: "Beer Money", serif;
  color: black;
  text-align: center;
  opacity: .8;
  font-size: 18px;
  display: inline-block;
  margin: 0 auto;

}

.title-rest {
  top: 30px;
  left: 15px;
}

.title-rest-language {
  margin-left: -20%;
}

.title-about {
  font-family: "Beer Money", serif;
  top: 25px;
  left: 38px;
}

.products-icon {
  position: relative;
  max-width: 80px;
  top: -8px;
  left: -4px;
}

.language-icon-container {
  width: 80px;
  height: 80px;
}

.language-icon-container img {
  height: 120px;
  bottom: 20px;
  left: 47%;
}

@media screen and (max-width: 1024px) {
  .menu-item {
    position: relative;
    background: none;
    width: 110px;
    height: 110px;
  }
}
</style>
