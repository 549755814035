<template>
  <div>
    <!-- <div class="inner-icon-container">
      <img :class="{'logo-image': !onPage, 'logo-on-page:': onPage}" alt="logo-image-ideinitsi" :src="require('@/assets/logo.png')">
      <img :class="[onPage ? 'logo-on-page' : 'logo-image']" alt="logo-image-ideinitsi" src="./../assets/logo.png">
    </div> -->

    <div class="logo-container">
      <img alt="Logo Image Ideinitsi" src="./../assets/logo.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoComponent",
  props: {
    onPage: Boolean
  }
}
</script>

<style scoped>
.logo-on-page {
  width: 260px;
  height: 150px;
  max-width: 275px;
  min-height: 150px;
  transform: scale(.7);
}
.logo-image {
  z-index: 10000;
  width: 260px;
  height: 150px;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 150px;
  position: absolute;
}

@media screen and (max-height: 599px) {
  .logo-container {
    height: 100px;
  }
}

@media screen and (max-height: 399px) {
  .logo-container {
    height: 70px;
  }
}

@media screen and (max-height: 299px) {
  .logo-container {
    height: 50px;
  }
}

@media screen and (min-height: 600px) {
  .logo-container {
    height: 120px;
  }
}

@media screen and (min-height: 700px) {
  .logo-container {
    height: 150px;
  }
}


@media screen and (min-height: 800px) {
  .logo-container {
    height: 170px;
  }
}

@media screen and (min-height: 1000px) {
  .logo-container {
    height: 200px;
  }
}

@media screen and (min-height: 1200px) {
  .logo-container {
    height: 230px;
  }
}

@media screen and (min-height: 1500px) {
  .logo-container {
    height: 250px;
  }
}

@media screen and (min-width: 1024px) {
  .logo-component {
    position: relative;
    top: 30px;
    transform: translate(72%, -20px);
    height: 20px;
  }
  .inner-icon-container {
    height: 55px;
  }
}
@media screen and (max-width: 768px) {
  .logo-on-page {
    width: 100%;
    max-width: 275px;
    min-height: 150px;
    transform: scale(.7);
  }
  .inner-icon-container {
    height: 45px;
  }
}

@media screen and (max-width: 1024px) {
  /* CSS rules here */
  .logo-component{
    position: relative;
  }
  .logo-component {
    text-align: center;
  }
  .inner-icon-container {
    height: 45px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1536px) and (max-height: 900px) {
  .logo-component {
    transform: translate(70%, -45px) scale(1);
    height: 10px;
  }
  .inner-icon-container {
    height: 45px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1536px) and (max-height: 665px) {
  .logo-component {
    transform: translate(70%, -20px) scale(1);
    height: 10px;
  }
  .inner-icon-container {
    height: 45px;
  }
}

@media (max-height: 630px) {
  /* CSS rules here */
  .logo-component{
    position: relative;
    margin-top: 5px;
  }
  .logo-component {
    text-align: center;
  }
  .inner-icon-container {
    height: 55px;
  }
}
@media screen and (orientation: landscape) and (max-height: 400px)  {
  .logo-image {
    height: 150px;
    width: 275px;
    transform: translate(0, -40px) scale(.6);
  }
  .logo-on-page {
    width: 100%;
  //max-width: 275px;
  //min-height: 90px;
    transform: translate(0, -30px) scale(.7);
  }
  .inner-icon-container {
    height: 55px;
  }
}

@media screen and (orientation: landscape) and (max-height: 365px)  {
  .logo-image {
    height: 150px;
    width: 275px;
    transform: translate(0, -40px) scale(.6);
  }
  .logo-on-page {
    width: 100%;
  //max-width: 275px;
  //min-height: 90px;
    transform: translate(0, -30px) scale(.7);
  }
  .inner-icon-container {
    height: 55px;
  }
}
</style>