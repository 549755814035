<template>
  <div>
    <logo-component :on-page='true' ></logo-component>
<!--    <central-background style="z-index: 0; zoom: 300%;" class="central-background"></central-background>-->
    <central-background style="z-index: 0;" class="central-background"></central-background>
    <div v-if="!isTablet" class="content-container"  ref='test' :key="rerender">
      <div class="return-home-button" @click="$router.go(-1)">
        <div title="Back to Menu" class="back-arrow">
          &#10140;
        </div>
      </div>
      <div class="information-panel">
        <div class="title">
          {{this.name}}
        </div>
        <div class="description-box">
          <ul v-for="(item, index) in this.description" :key="index">
            <li v-if="Object.keys(item)[0] === 'Описание'">{{Object.values(item)[0]}}</li>
            <li v-else>{{Object.keys(item)[0]}} {{Object.values(item)[0]}}</li>
          </ul>
        </div>
      </div>
      <span ref='imageContainerTablet' class="images-container-outer">
        <span :class="['image-container', {'added-padding': index % 2 === 0}]" v-for='(image, index) in this.images' :key="index">
          <img v-img :class="['grid-image-element', {'added-margin': index > 1}]" :src="getImageUrl(image_path, image)" alt="" />
        </span>
      </span>
    </div>
    <div v-if="isTablet" class="content-container-tablet" ref='contentContainerTablet' :key="rerender">
      <div class="return-home-button" @click="$router.go(-1)">
        <div title="Back to Menu" class="back-arrow">
          &#10140;
        </div>
      </div>
      <div class="information-panel-tablet">
        <div class="title-tablet">
          {{this.name}}
        </div>
        <div class="description-box-tablet">
          <ul v-for="(item, index) in this.description" :key="index">
            <li v-if="Object.keys(item)[0] === 'Описание'">{{Object.values(item)[0]}}</li>
            <li v-else>{{Object.keys(item)[0]}} {{Object.values(item)[0]}}</li>
          </ul>
        </div>
      </div>
      <span ref='imageContainerTablet' class="">
        <span class="image-container-tablet" v-for='(image, index) in this.images' :key="index">
          <img v-img class="grid-image-element-tablet" :src="getImageUrl(image_path, image)" alt="" />
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground.vue";
import LogoComponent from "@/components/LogoComponent.vue";
// import VueExpandableImage from 'vue-expandable-image'
import {gsap} from "gsap";

export default {
  name: "ProjectInfoComponent",
  components: {
    'central-background': CentralBackground,
    'logo-component': LogoComponent,
    // 'expandable-image': VueExpandableImage
  },
  data() {
    return {
      rerender: 0,
      isPanelOpen: false,
      description: undefined,
      images: [],
      image_path: '',
      name: '',
      informationPanelShow: false,
      pageHasLoaded: false,
      isTablet: false
    }
  },
  methods: {
    computeLayout() {
      if(window.innerWidth < 1024) {
        this.isTablet = true
      }
      else {
        this.isTablet = false
      }
    },
    getImageUrl(image_path, image) {
      let full_img_src = '/' + image_path + '/' + image
      // eslint-disable-next-line no-undef
      return require('./../../assets' + full_img_src)
    },
    removeActiveClasses() {
      const panels = document.querySelectorAll('.panel')
      panels.forEach(panel => {
        panel.classList.remove('active')
      })
    },
    selectPanel() {
      console.log('cukam buton')
      this.removeActiveClasses()

        if(!this.informationPanelShow) {
          const lhsPanel = document.querySelector('.lhs-panel')
          gsap.to(lhsPanel, {
            width: '10%',
            padding: '0',
            duration: 1,
            ease: 'power2.out'
          });
          gsap.to('.rhs-panel', {
            border: 'none',
            width: '80%',
            duration: .7,
            ease: 'power2.out'
          });
          this.informationPanelShow = !this.informationPanelShow
        }
        else if(this.informationPanelShow) {
          let lhs_panel = document.querySelector('.lhs-panel')
          lhs_panel.style.width = '100%'
          lhs_panel.style.padding = '1% 4%'

          let rhs_panel = document.querySelector('.rhs-panel')
          rhs_panel.style.width = ''
          this.informationPanelShow = !this.informationPanelShow
        }

      else {
        //if only one image is present
      }
    }
  },
  watch: {
    images: function () {
      return this.rerender += 1;
    }
  },
  created() {
    let {payload} = this.$route.params
    this.description = payload.description
    this.images = payload.images
    this.image_path = payload.image_path
    this.name = payload.name

    if(window.innerWidth < 1024) {
      return this.isTablet = true
    }
    window.addEventListener("resize", this.computeLayout);
  },
  mounted() {
    if(!this.isTablet) {
      setTimeout(() => {
        const timeline = gsap.timeline();

        timeline.fromTo(
            this.$refs.test,
            { opacity: 1 },
            { opacity: 0.9, duration: 0.5 }
        );

        const imageContainers = this.$refs.imageContainer.getElementsByClassName('image-container');
        const container = this.$el.querySelector('.information-panel-closed');

        gsap.from(container, {
          opacity: 0,
          y: 20,
          duration: 0.5,
          ease: 'power2.out',
        });

        gsap.from(imageContainers, {
          opacity: 0,
          y: 20,
          duration: 2,
          stagger: 1,
          ease: 'power2.out',
        });

      }, 1000)
    }
    else if(this.isTablet) {

      setTimeout(() => {
        const timeline = gsap.timeline();

        timeline.fromTo(
            this.$refs.contentContainerTablet,
            { opacity: 0 },
            { opacity: 0.9, duration: 0.5 }
        );


        timeline.play()
      },1000)
    }
    if(this.images.length === 1) {
      // setTimeout(() => {
      //   let image_element = document.querySelector('.grid-image-element')
      //   image_element.style.width = '85%'
      //   image_element.style.height = '85%'
      //
      //   let test = document.querySelector('.image-container')
      //   test.style.display = 'inline-block'
      //   test.style.textAlign = 'center'
      //   test.style.marginRight = '7%'
      // },500)
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.computeLayout);
  },
}
</script>

<style scoped>

.content-container {
  opacity: 0;
}
.content-template-wrapper {
  position: absolute;
}
.content-container {
  position: relative;
  transform: translate(20%, 20%);
  width: 70vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
}
.content-container-tablet {
  position: relative;
  transform: translate(5%, 15%);
  width: 90vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
  overflow: hidden;
  overflow-y: scroll;
}
.text-content-container {
  padding: 2.5%;
}
.first-phrase-span {
  display: block;
  margin: 10px 0;
}
li {
  margin: 10px;
}
.text-content {
  font-family: 'Comic Sans MS',serif;
  font-size: 22px;
  color: #f2f2f2;
}
.return-home-button {
  position: relative;
  top: 10px;
  left: 10px;
}
.back-arrow {
  font-size: 25px;
  line-height: 25px;

  width: 1.5em;
  height: 1.5em;
  padding: 5px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);

  color: white;
  border: 2px solid white;
  border-radius: 50%;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.back-arrow:hover {
  background: rgba(255,255,255,.5);
}
.images-container-outer {
  display: inline-block;
  padding: 1.5% 2.5%;;
  //flex-wrap: wrap;
  //flex-direction: row;
}
.image-container {
  //flex: 1 0 45%; /* explanation below */
}
.added-padding {
  padding-right: 2%;
}
.added-margin {
  margin-top: 2%
}
.image-container-tablet {
  flex-direction: column;
}
.image-element {
  width: 50%;
  //height: 300px;
  //padding: 0 2%;
}

.information-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.image-grid {
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*flex-direction: row;*/
  /*justify-content: space-evenly;*/
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 1% 4%;
  margin-left: 5%;
}

.image-grid-tablet {
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*flex-direction: row;*/
  /*justify-content: space-evenly;*/
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 1% 4%;
  margin-left: 5%;
}

.grid-image-element {
  width: 46%;
  //height: 47%;
  //padding: 5px;
}

.grid-image-element-tablet {
  width: 100%;
  //height: 47%;
  padding: 8px;
  border-radius: 16px !important;
}
.rhs-panel {
  height: 90%;
  padding: 0 20px;
  cursor: pointer;
}
.info-icon {
  margin-top: 15px;
}
.info-icon > img {
  /*position: absolute;*/
  /*transform: translate(-50%, 400%);*/
  height: 65px;

}
.information-panel-closed {
  height: 100%;
  font-size: 45px;
  width: 100px;
  text-align: center;
  color: white;
  font-family: 'Comic Sans MS',serif;
  font-weight: 300;
  text-transform: uppercase;
}

.image-panel-close {
  cursor: pointer;
  margin-top: 10%;
  border-right: 15px solid white;
  font-size: 75px;
  width: 100px;
  text-align: center;
  color: white;
  font-family: 'Comic Sans MS',serif;
  font-weight: 300;
  text-transform: uppercase;
}

panel {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  background-color: red;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  flex: 0.5;
  margin: 10px;
  position: relative;
  -webkit-transition: all 700ms ease-in;
}

.title {
  font-size: 24px;
  text-align: center;
  color: white;
  font-family: 'Comic Sans MS',serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 30px;
}

.title-tablet {
  font-size: 22px;
  text-align: center;
  color: white;
  font-family: 'Comic Sans MS',serif;
  font-weight: 300;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.description-box {
  //border: 2px solid white;
  margin: 5px;
}

.description-box-tablet {
  margin: 8px;
  //border: 3px solid white;
}
.description-box > ul {
  text-decoration: none;
  list-style-type: none;
  text-align: center;
  font-size: 24px;
  color: white;
  font-family: 'Comic Sans MS',serif;
  font-weight: 300;
  /* text-transform: uppercase; */
  text-align: left;
}

.description-box-tablet > ul {
  text-decoration: none;
  list-style-type: none;
  text-align: center;
  font-size: 14px;
  color: white;
  font-family: 'Comic Sans MS',serif;
  font-weight: 300;
  /* text-transform: uppercase; */
  text-align: left;
}
.panel.active {
  flex: 5;
}

.content-container {
  opacity: 0;
}

.content-container-tablet::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}
.content-container-tablet::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 8px;
}

.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
}
.content-container::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}
.content-container::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 8px;
}


</style>