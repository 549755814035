<template>
  <div>
    <logo-component :on-page='true' ></logo-component>
    <central-background style="z-index: 0" class="central-background"></central-background>
    <div class="content-template-wrapper">
      <div class="content-container overflow">
        <div class="return-home-button" @click="$router.go(-1)">
          <div title="Back to Menu" class="back-arrow">
            &#10140;
          </div>
        </div>
        <div class="text-content-container">
          <div class="text-content first-phrase" ref="sectionOne">
            <span class="first-phrase-span">Сравнение на Уютен мобилен еко дом с каравана / контейнерна къща</span>
          </div>
          <div class="text-content">
            <div class="info-section" ref="sectionTwo">
              <ul>
                <li class="name-border-style">
                  Прилики
                </li>
                <p>
                  Размерите, формата, възможността за подвижност, вътрешното разпределение.
                </p>
              </ul>
            </div>
            <div class="info-section" ref="sectionThree">
              <ul>
                <li class="name-border-style">
                  Разлики
                </li>
                <p>
                  Материалите, масивността, термалният комфорт, акустиката, енергийно
                  ефективното отопление/охлаждане/вентилация – всички те определящи нещо
                  интегрално и най-важно – уют.
                </p>
              </ul>
            </div>
            <div class="info-section" ref="sectionFour">
              <ul>
                <li class="name-border-style">
                  Предимства
                </li>
                <ul>
                  <li>Уют и усещане за дом</li>
                  <li>Екологични материали (предимно дървесина, каменна вата, стъкло)</li>
                  <li>Масивна конструкция и топлоизолация, даващи термална инертност</li>
                  <li>Добра звукоизолация (от заобикалящата среда и между помещенията)</li>
                  <li>Паропропускливи стени и таван, избягващи мухъл и задушност</li>
                  <li>По-висока енергийна ефективност</li>
                  <li>По-ниска първоначална инвестиция в сравнение с луксозна каравана</li>
                </ul>
              </ul>
            </div>
            <div class="info-section" ref="sectionFive">
              <ul>
                <li class="name-border-style">
                  Недостатъци
                </li>
                <ul>
                  <li>По-ниска степен на подвижност от каравана и кемпер</li>
                  <li>По-висока първоначална инвестиция в сравнение с контейнерна къща</li>
                </ul>
              </ul>
            </div>
<!--            <div class="image-container">-->
<!--              <img class="image-element left" src="./../assets/izolaciq.png" alt="Изолация еко дом">-->
<!--              <img class="image-element right" src="./../assets/spalniq.png" alt="Изолация еко дом">-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground.vue";
import LogoComponent from "@/components/LogoComponent.vue";

import {gsap} from "gsap";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Compare",
  components: {
    'central-background': CentralBackground,
    'logo-component': LogoComponent,
  },
  mounted() {
    const tl = gsap.timeline({ delay: 0.5 }); // Create a timeline

    tl.fromTo(this.$refs.sectionOne, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.sectionTwo, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.sectionThree, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.sectionFour, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.sectionFive, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
  },

}
</script>
<style scoped>
.content-template-wrapper {
  position: absolute;
}
.content-container {
  position: relative;
  transform: translate(20%, 20%);
  width: 70vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
}
.text-content-container {
  padding: 2.5%;
}
.first-phrase-span {
  display: block;
  margin: 10px 0;
}
li {
  margin: 10px;
}
.text-content {
  font-family: 'Comic Sans MS',serif;
  font-size: 22px;
  color: #f2f2f2;
}
.return-home-button {
  position: relative;
  top: 10px;
  left: 10px;
}
.back-arrow {
  font-size: 25px;
  line-height: 25px;

  width: 1.5em;
  height: 1.5em;
  padding: 5px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);

  color: white;
  border: 2px solid white;
  border-radius: 50%;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.back-arrow:hover {
  background: rgba(255,255,255,.5);
}
.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 100%;
}

.image-element {
  width: 50%;
  height: 300px;
  padding: 0 2%;
}
@media screen and (max-width: 1366px){
  .content-container {
    position: relative;
    transform: translate(5%, 15%);
    width: 90vw;
    height: 75vh;
    background: #04606B;
    opacity: .9;
    overflow: hidden;
    overflow-y: scroll;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }
  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
@media screen  and (min-width: 1366px) and (max-height: 900px) {
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }
  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}

</style>