<template>
  <div class="menu-list">
    <div class="menu-item one">
      <div class="circle-image-container">
        <img class="circle-image" src="./../assets/circle.png" alt="circle-image">
      </div>
      <div  class="inner-icon-container">
        <img class="icon-image" src="./../assets/gallery-icon.png">
      </div>
    </div>
    <div class="menu-item two">
      <div class="circle-image-container">
        <img class="circle-image" src="./../assets/circle.png" alt="circle-image">
      </div>
      <div  class="inner-icon-container">
        <img class="icon-image" src="./../assets/about-icon.png">
      </div>
    </div>
    <div class="menu-item three">
      <div class="circle-image-container">
        <img class="circle-image" src="./../assets/circle.png" alt="circle-image">
      </div>
      <div  class="inner-icon-container">
        <img class="icon-image" src="./../assets/people-icon.png">
      </div>
    </div>
    <div class="menu-item four">
      <div class="circle-image-container">
        <img class="circle-image" src="./../assets/circle.png" alt="circle-image">
      </div>
      <div  class="inner-icon-container">
        <img class="icon-image" src="./../assets/comparison-icon.png">
      </div>
    </div>
    <div class="menu-item five">
      <div class="circle-image-container">
        <img class="circle-image" src="./../assets/circle.png" alt="circle-image">
      </div>
      <div  class="inner-icon-container">
        <img class="icon-image" src="./../assets/contacts-icon.png">
      </div>
    </div>
    <div class="menu-item six">
      <div class="circle-image-container">
        <img class="circle-image" src="./../assets/circle.png" alt="circle-image">
      </div>
      <div  class="inner-icon-container">
        <img class="icon-image" src="./../assets/creation-icon.png">
      </div>
    </div>
    <div class="menu-item seven">
      <div class="circle-image-container">
        <img class="circle-image" src="./../assets/circle.png" alt="circle-image">
      </div>
      <div  class="inner-icon-container">
        <img class="icon-image" src="./../assets/products-icon.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuItemsHome"
}
</script>

<style scoped>

.menu-list {
  width: 100%;
  position: relative;

}
.menu-item {
  position: relative;
}
.one {
  left: 26%;
}
.two {
  left: 33%;
}
.three {
  left: 40%;
}
.four {
  left: 47%;
}
.five {
  left: 54%;
}
.six {
  left: 61.5%;
}
.seven {
  left: 68.5%;
}
.circle-image-container {
  position: absolute;
  top: 0;
  left: 0;
}
.inner-icon-container {
  position: absolute;
  top: 0;
  left: 0;
}
.circle-image {
  width: 100px;
  height: 100px;
}
.icon-image {
  height: 145px;
  position: relative;
  top: -20px;
  right: 45px;
}

@media screen and (max-width: 1500px) {
  .menu-list {
    margin-top: 20px;
  }
  .one {
    left: 20%;
  }
  .two {
    left: 30%;
  }
  .three {
    left: 40%;
  }
  .four {
    left: 50%;
  }
  .five {
    left: 60%;
  }
  .six {
    left: 70%;
  }
  .seven {
    left: 80%;
  }
}
</style>