<template>
  <div class="container">
    <div ref='logoComponent' class="logo-container">
      <img class="logo" src="./../assets/logo.png" alt="">
      <img ref='signatureImage' class="signature" src="./../assets/signature.png">
    </div>
<!--    <div class="signature-container" ref="signatureContainer">-->
<!--      <img class="signature" src="./../assets/signature.png">-->
<!--    </div>-->
<!--    <div class="loading-line-container">-->
<!--      <img  id="line" class="loading-line revealing-image" src="./../assets/line-image.svg" alt="">-->
<!--      <div class="clipping-mask"></div>-->
<!--    </div>-->
    <menu-items-home class="menu-items" ref="menuItems"></menu-items-home>
  </div>
</template>

<script>
import MenuItemsHome from "@/components/MenuItemsHome";
import {gsap} from "gsap";

export default {
  name: "LoadingPage",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    'menu-items-home':  MenuItemsHome
  },
  mounted() {
    const timeline = gsap.timeline();

    const menuItems = this.$refs.menuItems.$el.querySelectorAll('.menu-item');
    const logoElement = this.$refs.logoComponent;
    const signature = this.$refs.signatureImage


    document.addEventListener('DOMContentLoaded', function() {
        if (window.innerWidth >= 1230) {
          gsap.set(logoElement, {
            x: 100
          });
        }
    });
    document.addEventListener('resize', function() {
      if (window.innerWidth >= 1230) {
        gsap.set(logoElement, {
          x: 100
        });
      }
      else {
        gsap.set(logoElement, {
          x: 0
        });
      }
    });

    menuItems.forEach(item => {
      item.style.opacity = 0;
    });

    timeline.from(logoElement, {

      scale: 1.2,
      duration: 1.5,
      ease: 'power2.out',
    })
    .to(logoElement, {
      scale: 1,
      duration: 1,
      ease: 'power2.out',
    })
    .to(signature, {
      opacity: 1,
      y: 20,
      duration: 1,
      ease: 'power2.out',
      stagger: 0.2,
    })
    .to(menuItems, {
      opacity: 1,
      y: 20,
      duration: 1,
      ease: 'power2.out',
      stagger: 0.2,
    });

    const tl = gsap.timeline();

// Set up the animation
    tl.fromTo('.clipping-mask', { scaleX: 1 }, { scaleX: 0, duration: 1, backgroundColor: 'transparent' });

// Optionally, add additional animations for the image or other elements
    tl.from('.revealing-image', { opacity: 0, duration: 1 }, '-=0.5');
// Add more animations here if needed

// Trigger the timeline
    tl.play();

  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-bottom: 5%;
}

.loading-line {
  /*width: 50%;*/
}

.logo-container, .loading-line-container {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.clipping-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAF9F6; /* Change to white */
  transform-origin: right;
}

.revealing-image {
  position: relative;
  z-index: 1;
}

.logo {
  width: 60%;
}
/*.signature {*/
/*  opacity: 0;*/
/*  height: 200px;*/
/*  position: relative;*/
/*  top: 65%;*/
/*  right: 8%;*/
/*}*/

.icons-container {
  display: flex;
  flex-direction: row;

  /*flex-basis: 100%;*/
}

@media screen and (min-width: 1248px) {
  .signature {
    opacity: 0;
    height: 200px;
    position: relative;
    top: 65%;
    right: 8%;
  }
}

@media screen and (max-width: 1248px) {
  .menu-items {
    display: none;
  }
  .signature {
    display: none;
  }
  .logo-container {
    display: block;
    margin: 0 auto;
  }
  .logo {
    width: 100%;
  }
}

@media screen and (orientation: landscape) and (max-height: 486px) {
  .logo-container {
    display: block;
    margin: 0 auto;
  }
  .logo {
    width: 100%;
    height: 80vh;
  }
}

</style>