<template>
  <div id="app">
    <loading-page v-if="!hasPlayedLoadingEffect"></loading-page>
    <router-view v-else-if="hasPlayedLoadingEffect"></router-view>
  </div>
</template>

<script>
import LoadingPage from "@/view/LoadingPage";

export default {
  name: 'App',
  data() {
    return {
      hasPlayedLoadingEffect: false
    }
  },
  metaInfo: {
    title: 'Идейници – Къщурки на колела',
    meta: [
      { name: "description", content: "Безгрижни къщи, сътворени от вашата мечта с нашите знания и майсторство." }
    ]
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    'loading-page': LoadingPage
  },
  mounted() {
    setTimeout(() => {
      this.hasPlayedLoadingEffect = true
    }, 7000)

    // const language = navigator.languages
    //   ? navigator.languages[0]
    //   : (navigator.language || navigator.userLanguage);
    // if (language === 'en' || language === 'en-US' || language === 'en-GB') {
    //   window.location.href = `/en`;
    // } else if (language === 'nl' || language === 'nl-NL' || language === 'nl-BE') {
    //   window.location.href = `/nl`;
    // }
  }
}
</script>

<style>
@import "./styles/global.css";

html {
  overflow: hidden;
}
</style>
