<template>
  <div>
    <logo-component :on-page='true' ></logo-component>
    <central-background style="z-index: 0" class="central-background"></central-background>
    <div class="content-template-wrapper">
      <div class="content-container">
        <div class="return-home-button" @click="$router.go(-1)">
          <div title="Back to Menu" class="back-arrow">
            &#10140;
          </div>
        </div>
        <div class="text-content-container">
          <div class="text-content first-phrase">
            <span ref="phrase1" class="first-phrase-span">С внимание към човека и природата,<br>с внимание към нуждата и детайла.</span>
            <span ref="phrase2" class="first-phrase-span">С желание за независимост от местожителството,<br>с желание за свободен и екологичен живот.</span>
            <span ref="phrase3" class="first-phrase-span">С фокус към това как живееш,<br>с фокус към това как се чувстваш.</span>
          </div>
          <div class="text-content" ref="textContent">
            <p>За душата: Това са уютни домове.<br>За тялото: Те са винаги термално комфортни и вентилирани с пресен въздух.<br>За живота: Те са функционални, подредени, удобни, оптимизирани.<br>За протокола: Те са високо енергийно ефективни.<br>За джоба: Те се климатизират с енергия колкото за два-три лаптопа.</p>
            <p>Ние сме идейници заедно с вас - добре сте дошли да обмислим, обсъдим и създадем вашата къща за вашите нужди.</p>
            <p>Представяме примерен проект за ориентир. Това не е серийно производство, а напълно персонализиран мобилен дом.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground";
import LogoComponent from "@/components/LogoComponent.vue";
import {gsap} from "gsap";

export default {
  name: "about-view",
  components: {
    'central-background': CentralBackground,
    'logo-component': LogoComponent,

  },
  mounted() {
    const tl = gsap.timeline({ delay: 0.5 }); // Create a timeline

    // Set initial positions
    tl.fromTo(this.$refs.phrase1, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.phrase2, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.phrase3, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
    tl.fromTo(this.$refs.textContent, { opacity: 0, y: 150 }, { opacity: 1, y: 0, duration: 3 });

  }
}
</script>

<style scoped>
.content-template-wrapper {
  position: absolute;
}
.content-container {
  position: relative;
  transform: translate(20%, 20%);
  width: 70vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
}
.text-content-container {
  padding: 2.5%;
}
.first-phrase-span {
  display: block;
  margin: 10px 0;
}
li {
  margin: 10px;
}
.text-content {
  font-family: 'Comic Sans MS',serif;
  font-size: 24px;
  color: #f2f2f2;
}
.return-home-button {
  position: relative;
  top: 10px;
  left: 10px;
}
.back-arrow {
  font-size: 25px;
  line-height: 25px;

  width: 1.5em;
  height: 1.5em;
  padding: 5px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);

  color: white;
  border: 2px solid white;
  border-radius: 50%;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.back-arrow:hover {
  background: rgba(255,255,255,.5);
}
@media screen and (max-width: 1366px) {
  .content-container {
    position: relative;
    transform: translate(5%, 15%);
    width: 90vw;
    height: 75vh;
    background: #04606B;
    opacity: .9;
    overflow: hidden;
    overflow-y: scroll;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }
  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
@media screen  and (min-width: 1366px) and (max-height: 900px) {
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }
  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
</style>