<template>
  <div>
    <logo-component :on-page='true' ></logo-component>
    <central-background style="z-index: 0" class="central-background"></central-background>
    <div class="content-template-wrapper">
      <div class="content-container">
        <div class="return-home-button" @click="$router.go(-1)">
          <div title="Back to Menu" class="back-arrow">
            &#10140;
          </div>
        </div>
        <div class="text-content-container">
          <div class="text-content first-phrase">
            <span class="first-phrase-span paragraph">Къщурка на колела - Tiny House on Wheels</span>
          </div>
          <div class="text-content">
            <p class="paragraph">Къщурка на колела е концепция, оползотворяваща максималните законни
              размери на пътно превозно средство за живеене в малко уютно жилище. Тези
              подвижни къщи са широко разпространени в страни със скъпа земя и
              просторни пътища: Нова Зеландия, Австралия, Скандинавските държави,
              Канада, САЩ и др. През последното десетилетие привличат все по-голям
              интерес, поради поскъпването на живота, всеобхватния достъп до интернет,
              гъвкавия начин на живот, пандемията, природните бедствия и съвременните
              материали и технологии, улесняващи създаването на къщурки на колела.</p>

            <p class="paragraph">В Европа приложението на Къщурки на колела е чувствително по-тясно,
              поради редица причини, сред които: тесни, стръмни и накъдрени пътища;
              ограничаващи или оскъпяващи регулации за реализирането и
              транспортирането на големи, или тежки ремаркета, и преместваеми обекти;
              голяма гъстота на населението.</p>

            <p class="paragraph">Тази компания едновременно решава три предизвикателства за по-широкото навлизане на уютните мобилни еко домове в Европа и удовлетворението на ползвателите им:</p>

            <ul>
              <li>Създава напълно функционални и мобилни еко домове</li>
              <li>Транспортира мобилни домове между местата за отсядане</li>
              <li>Покрива съвременните изисквания за енергийна ефективност</li>
            </ul>
          </div>
          <div class="image-container">
            <img v-img class="image-element left" src="./../assets/concept_03.png" alt="Изолация еко дом">
            <img v-img class="image-element left" src="./../assets/concept_04.png" alt="Изолация еко дом">
            <img v-img class="image-element right" src="./../assets/concept_01.png" alt="Изолация еко дом">
            <img v-img class="image-element left right" src="./../assets/concept_02.png" style="margin-left: 2%" alt="Изолация еко дом">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentralBackground from "@/components/CentralBackground.vue";
import LogoComponent from "@/components/LogoComponent.vue";

import {gsap} from "gsap";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Concept",
  components: {
    'central-background': CentralBackground,
    'logo-component': LogoComponent,
  },
  mounted() {
    const paragraphs = document.querySelectorAll('.paragraph');
    const image_container = document.querySelectorAll('.image-container');

    gsap.from(paragraphs, { opacity: 0, y: -20, duration: 1, stagger: 0.5 })
    gsap.from(image_container, { opacity: 0, y: -20, duration: 1, stagger: 0.5 })
  }
}
</script>


<style scoped>
.content-template-wrapper {
  position: absolute;
}
.content-container {
  position: relative;
  transform: translate(20%, 20%);
  width: 70vw;
  height: 75vh;
  background: #04606B;
  opacity: .9;
}
.text-content-container {
  padding: 2.5%;
}
.first-phrase-span {
  display: block;
  margin: 10px 0;
}
li {
  margin: 10px;
}
.text-content {
  font-family: 'Comic Sans MS',serif;
  font-size: 22px;
  color: #f2f2f2;
}
.return-home-button {
  position: relative;
  top: 10px;
  left: 10px;
}
.back-arrow {
  font-size: 25px;
  line-height: 25px;

  width: 1.5em;
  height: 1.5em;
  padding: 5px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);

  color: white;
  border: 2px solid white;
  border-radius: 50%;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.back-arrow:hover {
  background: rgba(255,255,255,.5);
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  flex-basis: 100%;
}

.image-container .left{
  margin-right: 2% !important;
}

.image-container .right {
  margin-top: 2%;
}

.image-element {
  width: 47%;
//height: 300px;
}
@media screen  and (max-width: 1366px){
  .content-container {
    position: relative;
    transform: translate(5%, 15%);
    width: 90vw;
    height: 75vh;
    background: #04606B;
    opacity: .9;
    overflow: hidden;
    overflow-y: scroll;
  }
  .image-container {
    flex-direction: column;
  }
  .image-container .left{
    padding-right: 0% !important;
  }

  .image-element {
    width: 100%;
    margin-bottom: 10px;
  }
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }
  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}

@media screen  and (min-width: 1366px) and (max-height: 900px) {
  .content-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .content-container::-webkit-scrollbar {
    width: 8px;
  }
  .content-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
</style>